// @flow
import * as React from 'react';
import { graphql } from 'gatsby';
import Container from '../components/grid/container';
import DefaultLayout from '../layouts/default';

type Props = {
  data: {
    site: {
      siteMetadata: {
        title: string,
      }
    }
  }
};

export default ({ data: { site: { siteMetadata } } }: Props) => (
  <DefaultLayout>
    <Container withColumn>
      <h1>Sorry, that page was not found</h1>
      <p>
        Welcome to {siteMetadata.title}!
      </p>
    </Container>
  </DefaultLayout>
);

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
    }
`;
